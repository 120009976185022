const arItemsForDB = [];
let intervalId = undefined;

export function addItemArrayAndSend(arItem) {
    let currentKey = false;

    $(arItemsForDB).each(function (key, itemVal) {
        if (itemVal.ID === arItem.ID) {
            itemVal.TIME = 2;
            itemVal.QUANTITY = arItem.QUANTITY;
            itemVal.PRICE = arItem.PRICE;
            currentKey = true;
        }
    });
    if (currentKey === false) {
        arItem.TIME = 2;
        arItemsForDB.push(arItem);
    }

    if (intervalId === undefined) {
        intervalId = setInterval(() => sendArrayItems(arItemsForDB), 500);
    }

}

function sendArrayItems(ItemArray) {
    let product_data = [], new_time, time;
    if (ItemArray.length !== 0) {
        $(ItemArray).each(function (key, itemVal) {
            if (itemVal.TIME === 0) {

                product_data = {
                    'ID': itemVal.ID,
                    'QUANTITY': itemVal.QUANTITY,
                    'URL': itemVal.URL,
                    'TIME': itemVal.TIME,
                };

                $.ajax({
                    type: 'POST',
                    url: '/local/templates/Oshisha/include/add2basket.php',
                    data: 'product_data=' + JSON.stringify(product_data),
                    success: function (result) {
                        if (result.STATUS === 'success') {
                            deleteBasketItemTop(result);
                            $(arItemsForDB).each(function (i, val) {
                                if (val.ID === product_data.ID) {
                                    arItemsForDB.splice(i, 1)
                                }
                            });
                            if (arItemsForDB.length === 0 && intervalId !== undefined) {
                                clearInterval(intervalId);
                                intervalId = undefined;
                            }
                        }
                    }
                });
            } else {
                time = itemVal.TIME;
                new_time = time !== 0 ? (time - 1) : 0;
                product_data = {
                    'ID': itemVal.ID,
                    'QUANTITY': itemVal.QUANTITY,
                    'URL': itemVal.URL,
                    'TIME': new_time,
                };
                $(arItemsForDB).each(function (i, val) {
                    if (val.ID === product_data.ID) {
                        val.QUANTITY = product_data.QUANTITY;
                        val.TIME = product_data.TIME;
                    }
                })
            }
        });
    }
}

function deleteBasketItemTop(result) {
    if (result.QUANTITY !== '' && result.QUANTITY !== 0) {
        $('.spanBasketTop').attr('style', 'padding: 3px 6px;').text(result.QUANTITY);
        $('.price_basket_top').text(result.SUM_PRICE + ' ₽');
    } else {
        $('.spanBasketTop').remove();
        $('.price_basket_top').remove();
    }
}