import {createRoot} from 'react-dom/client';
import React, {StrictMode} from "react";
import CatalogProductPopup from "./components/catalog/CatalogProductPopup";
import "./script";
import "./css/index.css"

/**
 * CATALOG POPUP
 * @type {Root}
 */
const root = createRoot(document.getElementById('boxInitialPopup'));
document.addEventListener('click', function (e) {
    if (e.target.closest('.initialPopup') && e.target.tagName !== 'a' && e.target.tagName !== 'IMG') {
        let item = e.target.closest('.image_cart').querySelector('.initialPopup');
        if (item) {
            const productId = item.getAttribute('data-product-id');
            const areaBuy = item.getAttribute('data-area-buy');
            const area = item.getAttribute('data-area');
            const areaBuyQuantity = item.getAttribute('data-area-quantity');
            const groupedProduct = item.getAttribute('data-grouped-product');

            const setVisible = () => {
                root.render(
                    <StrictMode>
                        <CatalogProductPopup productId={productId} areaBuyQuantity={areaBuyQuantity} areaBuy={areaBuy}
                                             area={area} seePopup={false} groupedProduct={groupedProduct} setVisible={setVisible}/>
                    </StrictMode>);
            }
            root.render(
                <StrictMode>
                    <CatalogProductPopup productId={productId} areaBuyQuantity={areaBuyQuantity} areaBuy={areaBuy}
                                         area={area}  seePopup={true} groupedProduct={groupedProduct} setVisible={setVisible}/>
                </StrictMode>);
        }
    }

});
